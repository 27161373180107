import { useSelector } from "react-redux";
import { selectUrl } from "../features/url/urlSlice";
import portrait from "../images/index3.jpeg";
import portrait1 from "../images/index1.png";

const IndexPage = () => {
  const URL = useSelector(selectUrl);

  return (
    <div className="indexPage">
      <div className="intro">
        <div className="introText">
          <h2 id="firstName">Vít</h2>
          <h2 id="secondName">Španihel</h2>
        </div>
        <img src={portrait1} alt="portrait" id="portrait1" />
      </div>
      <div className="aboutPaintings">
        <iframe
          src="https://www.youtube.com/embed/RtxFRHTuhHM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="aboutMe">
        <img src={portrait} alt="portrait" id="portrait" />
        <div id="text">
          <h2>o mě</h2>
          <p>
            Jsem malíř, který se zaměřuje na zkoumání lidské psychiky a mezilidských vztahů. Mým cílem není jen zachytit okamžiky, ale také odhalit podstatu rovnováhy v životě. Kromě toho jsem spisovatel, který s humorem ukazuje, jak lež může deformovat a ovlivňovat naše vztahy. Prostřednictvím svých obrazů a psaní se snažím proniknout hlouběji do lidské duše a jejích neustálých bojů o rovnováhu a pravdu.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
