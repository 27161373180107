import { createSlice } from "@reduxjs/toolkit";

    //  const initialState = 'http://localhost:8080/'
      const initialState = 'https://omnibe.onrender.com/'


const urlSlice = createSlice({
    name: 'urlSlice',
    initialState,
    reducers:{}
})

export const selectUrl = (state) => state.url;

export default urlSlice.reducer;