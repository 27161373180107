

const PersonalData = () => {
return(
    <div className="personaldata">Ochrana vašich osobních údajů je pro nás důležitá.
     Na tomto webu neshromažďujeme žádné osobní údaje a nepoužíváme cookies ani jiné sledovací technologie. 
     Veškeré informace, které zde naleznete, jsou volně dostupné, a můžete si být jisti, že vaše soukromí zůstane chráněno.</div>
)
}

export default PersonalData