import paypalImg from '../images/paypal.png';
import QRcode from '../images/QR.png';

const Support = () => {
    return (
        <div className="support">
            <p className='uvodSupport'>
                Pokud se vám má tvorba líbí a chcete mě podpořit, budu vám vděčný. 
                Každý příspěvek pomáhá tomu, abych mohl dál tvořit umění, které má hloubku a rezonuje s lidskou podstatou. 
                Věřím, že umění by mělo vést k zamyšlení, otevírat nové pohledy a vést dialog o tom, co je skutečně důležité.
                 Nepodporuji povrchní či bezduché práce, které postrádají smysl. Podpořte skutečné umění, které má co říct!
            </p>
            <div className="bank">
                <div className="paypal">
                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="hosted_button_id" value="NDR9WKF5K8WY4" />
                        <input type="image" src={paypalImg} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" id='pp' />
                        <img alt="" border="0" src="https://www.paypal.com/en_CZ/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                </div>
                <h2>nebo naskenuj</h2>
                <div className='QRcode'>
                    <img src={QRcode} alt="code" />
                </div>
            </div>
        </div>
    );
}

export default Support;