import painting1 from "../images/vyznam/1.JPG"
import painting2 from "../images/vyznam/2.JPG"
import painting3 from "../images/vyznam/3.JPG"
import painting4 from "../images/vyznam/4.JPG"
import vitez from "../images/vyznam/vitez.png"


const Meaning = () => {
    return (
        <div className="meaning">
            <div className="wrapUvod">
                <div className="uvod">
                    „Přál bych si, aby lidé při pohledu na mé obrazy přemýšleli na několika úrovních.
                    Týká se to stylu tvorby, kde se snažím vyjádřit myšlenku chaosu v řádu a zachytit bezmyšlenkovitý proces tvorby.
                    Samotného názvu díla a série. Dále bych rád, aby pozorovatelé nacházeli význam toho,
                    co daný obraz znamená a reprezentuje.
                    <div className="brake"></div>
                    V některých případech hrají důležitou roli i barvy,
                    které mohou ovlivňovat, jak obraz působí na člověka.
                    A konečně, v některých kompozicích se snažím vyjádřit sílu spojení matematiky a přírody.“
                </div>

                <div className="imageBackOverlay"></div>
                <div className="imageBack"></div>
            </div>

            <div id="chaosInOrder1">
                <h1>Chaos v řádu</h1>
                <div className="chaosInOrder1_p">
                    <p>
                        V životě se musí dodržovat pravidla, aby se z něj nestalo peklo. Člověk má potom vliv na své okolí,
                        díky němu se prostředí okolo něj rozvíjí. Vyhýbá se utrpení, které není nezbytné.
                        Zároveň je však třeba udržovat chaos v podobě něčeho neznámého, čemu člověk plně nerozumí.
                    </p>

                    <p>
                        Chaos tak udržuje člověka bystrého a posouvá jej dopředu. Realistické tvary na obrazech zastupují řád,
                        který koexistuje spolu s rozmanitými čarami, jež reprezentují chaos. Umělec se snaží najít balanc,
                        čímž se pohybuje na úzké hraně. Chaotické čáry ukazují volnou, spontánní a bezmyšlenkovitou tvorbu.
                    </p>
                </div>
            </div>

            <div className="chaosInOrder">
                <div id="wrapChaosInOrder1">
                    <div id="chaosInOrder2">
                        <img src={painting1} alt="chaos in order1" className="chio" />
                        <p>
                            Chaos v řádu zdůrazňuje složitou dynamiku mezi řádem a chaosem v životě a uměleckém vyjádření.
                        </p>
                    </div>
                    <div id="chaosInOrder3">
                        <img src={painting2} alt="chaos in order1" className="chio" />
                        <p>
                            Řád zdůrazňuje důležitost pravidel a řádu v životě.
                            Je zobrazen pomocí realistických tvarů a struktur na obrazech, což představuje stabilní a řádné prvky ve světě.
                        </p>
                    </div>
                </div>
                <div id="wrapChaosInOrder1">
                    <div id="chaosInOrder4">
                        <img src={painting3} alt="chaos in order1" className="chio" />
                        <p>
                            Chaos je reprezentován nepravidelnými a rozmanitými liniemi,
                            které narušují řád a představují nepředvídatelnost a nejistotu.
                            Chaotické čáry symbolizují volnou, spontánní a bezmyšlenkovitou tvorbu.
                            Tento prvek připomíná, že někdy je nejlepší věci ponechat na náhodě a spontánnosti.
                        </p>
                    </div>
                    <div id="chaosInOrder5">
                        <img src={painting4} alt="chaos in order1" className="chio" />
                        <p>
                            Balanc je klíčovým prvkem stylu je hledání rovnováhy mezi řádem a chaosem.
                            Tento kontrast vytváří napětí a zajímavost v uměleckém díle.
                        </p>
                    </div>
                </div>
            </div>

            <div id="chaosInOrder6">
                <p>
                    Styl zdůrazňuje složitost lidského života a umělecké tvorby.
                    Použítím různých prvků a kontrastů je vyjádřeno, že jak řád,
                    tak chaos jsou nedílnou součástí existence a jejich harmonie může přinést hlubší porozumění světu kolem nás.
                    Mé umění se pohybuje na této úzké hraně mezi dvěma póly a hledá smysl a krásu v jejich spojení.
                </p>
            </div>

            <div className="doTmy">
                <h1>Když dostatečně dlouho hledíš do temnoty, spatříš světlo</h1>
                <p>
                    Série zdůrazňuje lidskou odolnost a schopnost najít světlo a naději i v nejtemnějších chvílích.
                    Je to umělecké vyjádření lidského vnitřního boje a schopnosti znovu povstat a posílit se po těžkých
                    životních zkušenostech.
                </p>
            </div>
            <div className="vitez">
                <div className="vitezWrap">
                    <h1>Vítězství duše nad tělem</h1>
                    <p>
                        Oslava lidské odolnosti a schopnosti dosáhnout vnitřního vítězství.
                        I když člověk někdy nezdědí nejlepší dispozice k životu, dokáže zvítězit pomocí své mysli.
                    </p>
                </div>
                <img src={vitez} alt="vitez" id="vitezImg" />
            </div>


        </div >

    )
}

export default Meaning;