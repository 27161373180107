import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUrl } from "../features/url/urlSlice";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const URL = useSelector(selectUrl);
  const key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [odeslano, setOdeslano] = useState(false);
  const [neodeslano, setNeOdeslano] = useState(false);
  const [sending, setSending] = useState(false);
  const [captchaIsDone, setCaptchaisDone] = useState(false);

  const handleSend = async (e) => {
    e.preventDefault();
    const username = process.env.REACT_APP_ADMIN_USERNAME;
    const password = process.env.REACT_APP_ADMIN_PASSWORD;
    const credentials = btoa(`${username}:${password}`);
    try {
      setSending(true)
      const result = await fetch(URL + "email/send", {
        method: "POST",
        body: JSON.stringify({ email, subject, message }),
        headers: {
          "Authorization": `Basic ${credentials}`,
          "Content-type": "application/json"
        },
      });
      if (result.status === 200) {
        setSending(false)
        setEmail("");
        setSubject("");
        setMessage("");
        setOdeslano(true);
      } else if (result.status === 400) {
        setSending(false)
        setNeOdeslano(true)
        setCaptchaisDone(false)
        console.log("Bad Request: Invalid input data");
      } else if (result.status === 401) {
        setSending(false)
        setNeOdeslano(true)
        setCaptchaisDone(false)
        console.log(
          "Unauthorized: You are not authorized to perform this action"
        );
      } else {
        setSending(false)
        setNeOdeslano(true)
        setCaptchaisDone(false)
        console.log("Server Error: Something went wrong on the server");
      }
    } catch (error) {
      setSending(false)
      setNeOdeslano(true)
      setCaptchaisDone(false)
      console.log("Error occurred while sending the request:", error);
    }
  };

  return (
    <div className="contact">
      <div className="contactdescription">
        <p>
          máte li nějaký dotaz, připomínku či prosbu neváhejte se na mě obrátit
          přes dotazovací formulář
        </p>
      </div>
      <form
        onSubmit={(e) => handleSend(e)}
        type="submit"
        className="contactform"
      >
        <div className="contact-initials">
          <div className="contact-email">
            <h2>Email:</h2>
            <input
              type="email"
              placeholder="johnDoe@email.com"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="contact-subhject">
            <h2>předmět:</h2>
            <input
              type="text"
              placeholder="dotaz"
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
        </div>
        <h2>zpráva:</h2>
        <textarea
          id="message"
          type="text"
          placeholder="text vaší zprávy:"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {sending ? (<p>probíhá odesílání zprávy</p>) :
          (<button id="contact-button" type="submit" disabled={!captchaIsDone}>Odeslat zprávu</button>)}
      </form>
      <p id="neodeslano">{neodeslano && "zpráva neodeslána :("}</p>
      <p id="odeslano">{odeslano && "zpráva odeslána :)"}</p>
      <ReCAPTCHA
        sitekey={key}
        onChange={(e) => (setCaptchaisDone(true),setNeOdeslano(false))}
      />
    </div>
  );
};

export default Contact;
