import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUrl } from "../features/url/urlSlice";
import { useState } from "react";

const Shop = () => {

    const URL = useSelector(selectUrl);
    const [shopData, setShopData] = useState("");

    useEffect(() => {
        const showData = async () => {
            try {
                const response = await fetch(URL + "getshop");
                response
                    .json()
                    .then((response) => setShopData(response))
                    .catch((err) => console.log(err));
                if (response.status === 200) {
                    console.log("shop loaded");
                } else {
                    console.log("internal error");
                }
            } catch (err) {
                console.log("internall error during fetch:" + err);
            }
        };

        showData();
    }, []);

    return (
        <div className="shop">
            <h1>Shop</h1>
            <div className="shop-container">
                {shopData &&
                    shopData.map((item) => (
                        <div key={item.id} className="shop-item">
                            <img src={URL + "images/" + item.image} alt={item.title} />
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                            <p>{item.price}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
}


export default Shop;