import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUrl } from "../features/url/urlSlice";
import loading from "../images/loading.gif";

const SinglePainting = () => {
  const URL = useSelector(selectUrl);
  const [painting, setPainting] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getSinglePaintingInfo = async () => {
      const username = process.env.REACT_APP_ADMIN_USERNAME;
      const password = process.env.REACT_APP_ADMIN_PASSWORD;
      const credentials = btoa(`${username}:${password}`);
      try {
        const result = await fetch(URL + "paintings/" + `${id}`, {
          method: "GET",
          headers: {
            "Authorization": `Basic ${credentials}`,
            "Content-type": "application/json"
          },
        });
        if (result.status === 200) {
          result.json().then((data) => setPainting(data));
        } else {
          console.log("internal error");
        }
      } catch (err) {
        console.log("internal error during fetch:" + err);
      }
    };
    getSinglePaintingInfo();
  }, []);

  const {
    paintingName,
    bigPaintingData,
    mediumPaintingData,
    additionalPaintingData,
    description,
    series,
    year,
    status,
    additionalDescription
  } = painting;

  return (
    <>
      {painting ? (
        <div className="singlepainting">
          <div className="singlepainting-description">
            <h1>{paintingName && paintingName}</h1>
            <p>{description && description}</p>
            <h3>{series && series}</h3>
            <h3>{year}</h3>
          </div>
            <img src={`data:image/jpeg;base64,${painting.bigPaintingData}`} alt="bigPainting" className="bigPainting"/>
          <div className="singlepainting-additional">
            <p>{additionalDescription && additionalDescription}</p>
            <div>
              {additionalPaintingData && (
                <img
                  className="additionalcover"
                  src={`data:image/jpeg;base64,${painting.additionalPaintingData}`}
                  alt="additionalPainting"
                />
              )}
            </div>
            <button onClick={() => navigate("/paintings")}>zpět</button>
          </div>
        </div>
      ) : (
        <div className="loading">
          <img src={loading} alt="Loading..." />
          Loading...
        </div>
      )}
    </>
  );
};

export default SinglePainting;
