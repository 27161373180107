import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import logo from "./images/logo.png";
import { useSelector, useDispatch } from "react-redux";

const Header = () => {
  const userName = useSelector((state) => state.users.name);

  const [activeHmaburger, setActiveHamburger] = useState(false);
  const [activeNavMenu, setactiveNavMenu] = useState(false);
  const [userRole, setUseRole] = useState(""); //userContext
  const [navbar, setnavbar] = useState('navbar');
  const navMenuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setnavbar('navbarDown');
      } else {
        setnavbar('navbar');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const showMenu = () => {
    setActiveHamburger((current) => !current);
    setactiveNavMenu((current) => !current);
  };

  return (
    <header>
      <nav className={navbar}>
        <Link to="/" className="nav-branding">
          <img src={logo} alt="logo" />
        </Link>
        <ul className={activeNavMenu ? "nav-menu" : "nav-menu-non"}>
          <div className="nav-menu-list">
            <li className="nav-item">
              <Link to="/paintings" className="nav-link">
                obrazy
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/meaning" className="nav-link">
                význam
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/books" className="nav-link">
                Fabrika
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                kontakt
              </Link>
            </li>
            {/*<li className="nav-item">
              <Link to="/shop" className="nav-link">
                shop
              </Link>
  </li>*/}
            <li className="nav-item">
              <Link to="/support" className="nav-link">
                mecenáš
              </Link>
            </li>
          </div>
        </ul>
        <div
          onClick={showMenu}
          className={activeHmaburger ? "hamburger" : "nothamburger"}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
