import "./css/Books.css";
import "./css/Footer.css";
import "./css/General.css";
import "./css/Header.css";
import "./css/IndexPage.css";
import "./css/Paintings.css";
import "./css/SinglePainting.css";
import "./css/Support.css";
import "./css/Shop.css";
import "./css/Contact.css";
import "./css/PersonalData.css";
import "./css/Meaning.css";
import { Route, Routes } from "react-router-dom";
import Books from "./pages/Books";
import Contact from "./pages/Contact";
import IndexPage from "./pages/IndexPage";
import Meaning from "./pages/Meaning";
import Paintings from "./pages/Paintings";
import Shop from "./pages/Shop";
import Support from "./pages/Support";
import Layout from "./Layout";
import PersonalData from "./pages/PersonalData";
import { UserContextProvider } from "./UserContext";
import SinglePainting from "./pages/SinglePainting";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path="/books" element={<Books />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/meaning" element={<Meaning />} />
          <Route path="/paintings" element={<Paintings />} />
          <Route path="/paintings/:id" element={<SinglePainting />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/support" element={<Support />} />
          <Route path="/personaldata" element={<PersonalData />} />
        </Route>        
      </Routes>
    </UserContextProvider>
  );
}

export default App;
